<template>

  <div>
    <v-alert
      v-model="isAlertVisible[msgKey]"
      v-for="(msg,msgKey) in responseMessages"
      :key="msgKey"
      :color="msg.valid ? 'success' : 'error'"
      dismissible
      dark
    >
      {{msg.title}} | {{msg.text}}
    </v-alert>
  <v-card id="channel-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <router-link :to="'/bewerber/erstellen'" >
          <v-btn
            color="primary"
            class="me-3"
          >
            <span>Bewerber erstellen</span>
        </v-btn>
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filter.searchString"
          dense
          outlined
          hide-details
          placeholder="Bewerber suchen"
          class="channel-list-search me-3"
        ></v-text-field>
      </div>

      <div class="d-flex align-center pb-5">
        <v-select
          v-model="filter.status"
          label="Status"
          outlined
          class="channel-list-search me-3"
          dense
          hide-details
          :items="filterStateOptions"
          item-text="key"
          item-value="value"
        ></v-select>
      </div>
      <div class="d-flex align-center pb-5">
        <inline-date-picker :date="filter.datumVon" label="Erstellt von" name="datumVon" @updateDate="updateFilter"/>
      </div>
      <div class="d-flex align-center pb-5">
        <inline-date-picker :date="filter.datumBis" label="Erstellt bis" name="datumBis" @updateDate="updateFilter"/>
      </div>
    </v-card-text>


    <v-card v-if="copyPermissions.length > 0 ? true : false">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <v-select
            v-model="selectedMultiAction"
            label="Aktionen"
            outlined
            dense
            :items="['Kopieren nach']"
            hide-details
            :disabled="Boolean(!selectedTableData.length)"
            @change="callMultiAction"
            class="invoice-list-actions me-3"
          ></v-select>
        </div>
      </v-card-text>
    </v-card>
    <v-data-table
      v-model="selectedTableData"
      :headers="headers"
      :items="listCandidates"
      item-key="idThBewerber"
      :options.sync="options"
      :server-items-length="totalAmountList"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :search="filter.searchString"
      class="text-no-wrap"
      :show-select="copyPermissions.length > 0 ? true : false"
      no-data-text="Keine Einträge vorhanden"
      no-results-text="Ihre Suche ergab keinen Treffer"
      :footer-props="{
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}',
        itemsPerPageOptions: [5,10,25,50,-1]
      }"
    >
      <template
        v-slot:top
      >
        <v-dialog
          v-model="dialogDelete"
          width="500px"
        >
          <v-card>
            <v-card-text
              class="text-center"
            >
              Möchten Sie <strong>&nbsp;{{ deleteItem.vorname }} {{ deleteItem.name }}&nbsp;</strong> wirklich löschen?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="deleteCandidateConfirm"
              >
                Löschen
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="closeDelete"
              >
                Abbrechen
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogCopyCandidate"
          width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Bewerber kopieren nach</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-select
                      v-model="copyReceiver"
                      label="Headhunter(Empfänger)"
                      outlined
                      dense
                      :items="copyPermissions"
                      item-text="displayNameReceiver"
                      item-value="idThHeadhunterEmpfaenger"
                      class="invoice-list-actions "
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="copyCandidatesToOtherHeadhunterConfirm()"
              >
                Kopieren
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="closeCopyCandidate()"
              >
                Abbrechen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog
          v-model="dialogEdit"
          width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editItem.name"
                      label="Name"
                      outlined
                      :rules="[nameRules]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.memo"
                      label="Memo"
                      auto-grow
                      outlined
                      rows="3"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="editValidationError"
                @click="editCandidateConfirm()"
              >
                Speichern
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="closeEdit()"
              >
                Abbrechen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #[`item.idThBewerber`]="{item}">
        #{{ item.idThBewerber }}
      </template>

      <template #[`item.job`]="{item}">

        <span >
          {{ item.job }}
          <br>
          <v-chip
            v-if="item.idThJobDefinition > 0"
            small
            :style="'color:'+item.jobDefinitionHexcodeFarbe+';'"
            class="v-chip-light-bg font-weight-semibold text-capitalize mt-2"
          >
          {{ item.jobDefinitionDisplayName }}
        </v-chip>
        </span>
      </template>

      <template #[`item.zeitErstellungDE`]="{item}">

        <span >
          {{ item.zeitErstellungDE }}
          <template v-if="item.zeitErstellungInWorten != ''">
            <br>{{item.zeitErstellungInWorten}}
          </template>
        </span>
      </template>

      <template #[`item.angabeDsgvo`]="{item}">
        <v-row class="compact">
            <v-col cols="3">
              <div title="DSGVO">
                <v-icon
                  size="20"
                  :color="item.angabeDsgvo == '1' ? 'success' : ''"
                >
                  {{ icons.mdiCheckboxMarkedCircleOutline  }}
                </v-icon>
              </div>
            </v-col>
            <v-col cols="3">
              <div title="Suchparam.">
                <v-icon
                  size="20"
                  :color="item.angabeSuchparameter == '1' ? 'success' : ''"
                >
                  {{ icons.mdiAccountSearchOutline  }}
                </v-icon>
              </div>
            </v-col>
            <v-col cols="3">
              <div title="Unterlagen">
                <v-icon
                  size="20"
                  :color="item.angabeUnterlagen == '1' ? 'success' : ''"
                >
                  {{ icons.mdiTextBoxMultipleOutline }}
                </v-icon>
              </div>
            </v-col>
            <v-col cols="3">
              <div title="Öffentlich">
                <v-icon
                  size="20"
                  :color="item.angabeOeffentlich == '1' ? 'success' : ''"
                >
                  {{ icons.mdiEarth }}
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-chip>
        </span>
      </template>

      <template #[`item.vorname`]="{item}">

        <div class="d-flex align-center">
          <v-avatar
            :class="`v-avatar-light-bg --text`"
            size="30"
          >
            <v-img
              v-if="item.photo"
              :src="'api/bewerber/?aktion=outputPhoto&inputData[idThBewerber]='+item.idThBewerber"
            ></v-img>
            <span
              v-else
              class="font-weight-medium"
            >{{ avatarText(item.vorname) }}</span>
          </v-avatar>
          <div class="d-flex flex-column ms-3 pa-3">
            <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.vorname }} {{ item.name }} {{ getBirthdayText(item.geburtsalter) }}</span>
            <span class="text-xs">{{ item.personalInformation.address.street }}</span>
            <span class="text-xs">{{ item.personalInformation.address.postcode }} {{ item.personalInformation.address.city }}</span>
            <span class="text-xs">{{ item.personalInformation.email }}</span><br><br>
          </div>
        </div>
      </template>
      <template #[`item.actions`]="{item}">
        <div class="">
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <a :href="'api/bewerber/pdf/'+item.idThBewerber+'/'" target="_blank">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiFilePdf }}
                  </v-icon>
                </v-btn>
              </a>
            </template>
            <span>PDF</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <router-link :to="'/bewerber/editieren/joinvision/'+item.idThBewerber" >
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </router-link>
            </template>
            <span>Bearbeiten</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="deleteCandidate( item )"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Löschen</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiFilePdf,
  mdiCheckboxMarkedCircleOutline,
  mdiAccountSearchOutline,
  mdiTextBoxMultipleOutline,
  mdiEarth,
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref, onMounted, nextTick} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import router from '@/router'

export default {
  name: 'channel-list',
  components: {
    InlineDatePicker
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const selectedMultiAction = ref('')
    const selectedTableData = ref([])
    const copyReceiver = ref(0)
    const copyPermissions = ref([])
    const dialogCopyCandidate = ref(false)
    const isAlertVisible = ref([])
    const responseMessages = ref([])

    onMounted(() => {
      axios.get('/api/bewerber/?aktion=fetchCopyPermissions').then((response) => copyPermissions.value = response.data.permissions)
    })

    const copyCandidatesToOtherHeadhunter = (campaigns) => {
      dialogCopyCandidate.value = true;
    }

    const copyCandidatesToOtherHeadhunterConfirm = () => {
      axios.post('api/bewerber/', {
        aktion: 'copyCandidatesToOtherHeadhunter',
        candidates: selectedTableData.value.map(item => item['idThBewerber']),
        idThHeadhunterEmpfaenger: copyReceiver.value,
      })
        .then((response) => {
          isAlertVisible.value = [];
          responseMessages.value = response.data.messages;
          selectedTableData.value = [];
          closeCopyCandidate();
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const callMultiAction = (event, key) => {
      if(selectedMultiAction.value === "Kopieren nach"){
        copyCandidatesToOtherHeadhunter(selectedTableData.value.map(item => item['idThBewerber']));
      }
    }

    const closeCopyCandidate = () => {
      dialogCopyCandidate.value = false;
      nextTick(() => {
        selectedMultiAction.value = 0;
      })
    }

    return {
      vm,
      router,
      selectedMultiAction,
      selectedTableData,
      copyReceiver,
      copyPermissions,
      dialogCopyCandidate,
      closeCopyCandidate,
      copyCandidatesToOtherHeadhunterConfirm,
      callMultiAction,
      responseMessages,
      isAlertVisible,
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiFilePdf,
        mdiCheckboxMarkedCircleOutline,
        mdiAccountSearchOutline,
        mdiTextBoxMultipleOutline,
        mdiEarth,
      },
      filter: { searchString: '', status: 'offene',datumVon:'',datumBis:''},
      loading: false,
      totalAmountList: 0,
      filterStateOptions : [
        {value:"all",                         key:"Alle"},
        {value:"offene",                      key:"Offene"},
        {value:"beworben",                    key:"Beworben"},
        {value:"lebenslaufErhalten",          key:"Lebenslauf erhalten"},
        {value:"interviewGemacht",            key:"Interview gemacht"},
        {value:"imSale",                      key:"Im Sale"},
        {value:"vorstellungsgespraech",       key:"Vorstellungsgespräch"},
        {value:"vermittelt",                  key:"Vermittelt"},
        {value:"archiviertQualifiziert",      key:"Archiviert Qualifiziert"},
        {value:"archiviertNichtQualifiziert", key:"Archiviert Nicht Qualifiziert"}
      ],
      filterAngabeAktivOptions: [
        { angabeAktiv: 1, description: 'aktiv' },
        { angabeAktiv: 0, description: 'inaktiv' },
      ],
      filterAngabeArchiviertOptions: [
        { angabeAktiv: 1, description: 'archiviert' },
        { angabeAktiv: 0, description: 'nicht archiviert' },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idThBewerber'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idThBewerber',
        },
        {text: 'Bewerber', value: 'vorname',sortable: false},
        {text: 'Aktueller Beruf', value: 'job',sortable: false},
        {text: 'Erstellt', value: 'zeitErstellungDE'},
        {text: 'Infos', value: 'angabeDsgvo'},
        {text: 'Status', value: 'displayStatus'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listCandidates: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idThBewerber: 0,
        name: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idThBewerber: 0,
        name: '',
        memo: '',
      },
      defaultItem: {
        idThBewerber: 0,
        name: '',
        memo: '',
      },
      editValidationError : true,

    }
  },
  mounted() {
    this.fetchCandidates()
  },
  watch: {
    options: {
      handler() {
        this.fetchCandidates()
      },
    },
    filter: {
      handler() {
        this.fetchCandidates()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Bewerber hinzufügen' : 'Bewerber bearbeiten'
    },
    getBirthdayText() {
      return text => { return text ? `(${text})` : ''}
    },
  },
  methods: {
    fetchCandidates() {
      this.loading = true
      axios.post('api/bewerber/', {
        aktion: 'showOverviewCandidates',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listCandidates = response.data.candidates || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setCandidateState(idThBewerber, state) {
      axios.post('api/bewerber/', {
        aktion: 'changeCandidateState',
        idThBewerber: idThBewerber,
        newState: state,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchCandidates()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteCandidate (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    deleteCandidateConfirm () {
      axios.post('api/bewerber/', {
        aktion: 'deleteCandidate',
        inputData :{idThBewerber: this.deleteItem.idThBewerber},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchCandidates()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    editCandidate (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listCandidates.indexOf(item)
        this.editItem = JSON.parse(JSON.stringify(item))
      }
    },
    nameRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Name muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Name kann maximal 45 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },
    editCandidateConfirm () {
      axios.post('api/bewerber/', {
        aktion: this.editIndex === -1 ? 'createCandidate' : 'editCandidate',
        data: this.editItem,
      })
        .then((response) => {
            if (response.data.valid === true) {
              this.vm.$toastr.s(response.data.message.title, response.data.message.text);
              this.fetchCandidates()
            }
            else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
  .channel-list-angabeAktiv {
    max-width: 11.3rem;
  }
  .channel-list-angabeArchiviert {
    max-width: 11.3rem;
  }
}
.compact .col-3 {
  padding: 2px;
}
</style>
